import type { ComponentProps } from "react"
import { Container } from "~/components/ui/container"
import { type VariantProps, cva } from "~/lib/cva"
import { cx } from "~/lib/cva"

export const sectionVariants = cva({
  base: "peer relative w-full border-t first-of-type:border-0 first-of-type:mt-16 md:first-of-type:mt-20 lg:first-of-type:mt-24",

  variants: {
    theme: {
      white: "bg-white",
      cream: "bg-transparent",
    },
    topSpacing: {
      none: "pt-0 !border-0",
      tiny: "pt-4 md:pt-6",
      small: "pt-8 md:pt-10 lg:pt-12",
      medium: "pt-10 sm:pt-12 md:pt-16",
      large: "pt-12 sm:pt-16 md:pt-20",
    },
    bottomSpacing: {
      none: "pb-0",
      tiny: "pb-4 md:pb-6",
      small: "pb-8 md:pb-10 lg:pb-12",
      medium: "pb-10 sm:pb-12 md:pb-16",
      large: "pb-12 sm:pb-16 md:pb-20",
    },
  },

  defaultVariants: {
    theme: "cream",
    topSpacing: "large",
    bottomSpacing: "large",
  },
})

export type SectionProps = ComponentProps<"section"> &
  VariantProps<typeof sectionVariants> & {
    spacing?: VariantProps<typeof sectionVariants>["topSpacing"]
    containerClassName?: string
  }

export const Section = ({
  children,
  className,
  theme = "cream",
  spacing = "large",
  topSpacing,
  bottomSpacing,
  containerClassName,
  ...props
}: SectionProps) => {
  return (
    <section
      data-theme={theme}
      className={sectionVariants({
        theme,
        topSpacing: topSpacing || spacing,
        bottomSpacing: bottomSpacing || spacing,
        className,
      })}
      {...props}
    >
      <Container className={cx("flex flex-col gap-10 md:gap-12 lg:gap-16", containerClassName)}>
        {children}
      </Container>
    </section>
  )
}

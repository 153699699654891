import { Slot } from "@radix-ui/react-slot"
import { IconLoader } from "@tabler/icons-react"
import type { ComponentProps, ReactNode } from "react"
import { Children, isValidElement } from "react"
import { Slottable } from "~/components/utils/slottable"
import { type VariantProps, cva, cx } from "~/lib/cva"

export const buttonVariants = cva({
  base: [
    "group/button relative inline-flex items-center justify-center border border-transparent font-medium text-left rounded-md",
    "disabled:opacity-60 disabled:pointer-events-none",
  ],

  variants: {
    variant: {
      primary: "text-background bg-foreground opacity-90 hover:opacity-100",
      secondary:
        "border-border bg-background text-foreground/75 hover:text-foreground hover:border-foreground/20",
      ghost: "text-foreground/75 hover:text-foreground",
    },
    size: {
      sm: "px-3 py-1 gap-[0.66ch] text-[13px]",
      md: "px-4 py-1.5 gap-[0.75ch] text-[13px] sm:text-sm",
      lg: "px-5 py-2.5 gap-[1ch] text-sm font-display -tracking-micro sm:text-base rounded-lg",
    },
    isAffixOnly: {
      true: "",
    },
    isPending: {
      true: "[&>*:not(.animate-spin)]:text-transparent select-none",
    },
  },

  compoundVariants: [
    // Is affix only
    { size: "sm", isAffixOnly: true, class: "px-1" },
    { size: "md", isAffixOnly: true, class: "px-1.5" },
    { size: "lg", isAffixOnly: true, class: "px-2" },
  ],

  defaultVariants: {
    variant: "primary",
    size: "md",
  },
})

export const buttonAffixVariants = cva({
  base: "shrink-0 size-[1.2em] my-[0.15em]",
})

export type ButtonProps = Omit<ComponentProps<"button">, "size" | "prefix"> &
  Omit<VariantProps<typeof buttonVariants>, "isAffixOnly"> & {
    /**
     * If set to `true`, the button will be rendered as a child within the component.
     * This child component must be a valid React component.
     */
    asChild?: boolean

    /**
     * If set to `true`, the button will be rendered in the pending state.
     */
    isPending?: boolean

    /**
     * The slot to be rendered before the label.
     */
    prefix?: ReactNode

    /**
     * The slot to be rendered after the label.
     */
    suffix?: ReactNode
  }

export const Button = ({
  children,
  className,
  disabled,
  asChild,
  isPending,
  prefix,
  suffix,
  variant,
  size,
  ...props
}: ButtonProps) => {
  const isChildrenEmpty = (children: ReactNode) => {
    return Children.count(children) === 0
  }

  const useAsChild = asChild && isValidElement(children)
  const Component = useAsChild ? Slot : "button"

  // Determine if the button has affix only.
  const isAffixOnly = isChildrenEmpty(children) && (!prefix || !suffix)

  return (
    <Component
      disabled={disabled ?? isPending}
      className={cx(buttonVariants({ variant, size, isAffixOnly, isPending, className }))}
      {...props}
    >
      <Slottable child={children} asChild={asChild}>
        {child => (
          <>
            <Slot className={buttonAffixVariants()}>{prefix}</Slot>
            {!isChildrenEmpty(child) && (
              <span className="flex-1 truncate only:text-center">{child}</span>
            )}
            <Slot className={buttonAffixVariants()}>{suffix}</Slot>

            {!!isPending && <IconLoader className="absolute size-[1.25em] animate-spin" />}
          </>
        )}
      </Slottable>
    </Component>
  )
}

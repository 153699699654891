import type { ComponentProps } from "react"
import { cx } from "~/lib/cva"

export const Container = ({ className, ...props }: ComponentProps<"div">) => {
  return (
    <div
      className={cx("w-full max-w-screen-lg mx-auto px-6 sm:px-8 md:px-10 lg:px-12", className)}
      {...props}
    />
  )
}

import type { ComponentProps } from "react"
import { Heading } from "~/components/ui/heading"
import { type VariantProps, cva, cx } from "~/lib/cva"

const introVariants = cva({
  base: "flex flex-col gap-y-4 w-full max-w-md sm:max-w-xl md:max-w-3xl",

  variants: {
    alignment: {
      start: "items-start text-start mr-auto",
      center: "items-center text-center mx-auto",
      end: "items-end text-end ml-auto",
    },
  },

  defaultVariants: {
    alignment: "center",
  },
})

type IntroProps = ComponentProps<"div"> & VariantProps<typeof introVariants>

const Intro = ({ className, alignment, ...props }: IntroProps) => {
  return <div className={cx(introVariants({ alignment, className }))} {...props} />
}

const IntroTitle = ({ size = "h1", ...props }: ComponentProps<typeof Heading>) => {
  return <Heading size={size} {...props} />
}

const IntroDescription = ({ className, ...props }: ComponentProps<"h2">) => {
  return (
    <h2
      className={cx(
        "w-full max-w-2xl text-pretty text-foreground/65 sm:text-lg/normal lg:text-xl/normal",
        className,
      )}
      {...props}
    />
  )
}

export { Intro, IntroTitle, IntroDescription }
